import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Badge } from 'react-bootstrap'
import dynamic from 'next/dynamic'
// import Trans from 'next-translate/Trans'

import styles from './style.module.scss'
// import articleStyles from '../style.module.scss'
import { convertDt24h, convertHMS, dateCheck, getArticleImg, getImgURL } from '@shared-submodule/utils'
import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import { BLUR_DATA_URL_BASE64, DEFAULT_BLOG_READ } from '@shared-submodule/sm-constants'
import CustomLink from '@shared/components/customLink'
import MyImage from '@shared/components/myImage'
import Calendar from '@assets/images/placeholder/calendar.svg'
import View from '@assets/images/placeholder/view.svg'
const ArticleStyles = dynamic(() => import('@shared/components/article/articleStyle'))

// const MyImage = dynamic(() => import('@shared/components/myImage'))
function ArticleMedium({ data, isVideo, isSmall, index, isFirstImg }) {
  return (
    <ArticleStyles>
      {(articleStyles) => (
        <article id={data?._id} className={`${articleStyles.article} ${styles.articleMedium} ${isVideo && articleStyles.video}`}>
          <Row className="row-8">
            <Col xs={5} sm={4} xl={isSmall && 3}>
              <CustomLink href={`/${data?.oSeo?.sSlug || '404'}`} prefetch={false}>
                <a className=''>
                  <div className={`${articleStyles.postimg} ${styles.postimg} block-img`}>
                    {isVideo && (
                      <MyImage
                        priority={isFirstImg}
                        src={getImgURL(data?.sThumbnailUrl) || noImage}
                        alt={data?.sTitle}
                        blurDataURL={BLUR_DATA_URL_BASE64}
                        placeholder="blur"
                        height="80"
                        width="128"
                        layout="responsive"
                        sizes="(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px"
                      />
                    )}
                    {!isVideo && (
                      <MyImage
                        priority={isFirstImg}
                        src={getArticleImg(data)?.sUrl || noImage}
                        alt={getArticleImg(data)?.sText || data?.sSrtTitle}
                        placeholder="blur"
                        blurDataURL={BLUR_DATA_URL_BASE64}
                        height="80"
                        width="128"
                        layout="responsive"
                        sizes="(max-width: 767px) 120px, (max-width: 991px) 180px, (max-width: 1190px) 200px, 240px"
                      />
                    )}
                    {isVideo && (
                      <Badge bg="primary" className={`${styles.badge} ${articleStyles.badge} ${isVideo && 'video-badge'}`}>
                        {convertHMS(data?.nDurationSeconds)}
                      </Badge>
                    )}
                  </div>
                </a>
              </CustomLink>
            </Col>
            <Col xs={7} sm={8} xl={isSmall && 8} className="d-flex flex-column justify-content-between">
              <div className={styles.postDesc}>
                <h3 className="small-head">
                  <CustomLink href={`/${data?.oSeo?.sSlug || '404'}`} prefetch={false}>
                    <a>{data?.sSrtTitle || data?.sTitle}</a>
                  </CustomLink>
                </h3>
                {data?.sDescription && <p className={`${articleStyles.desc} ${styles.desc} `}>{data?.sDescription}</p>}
              </div>
              {(data?.dPublishDisplayDate || data?.dPublishDate || data?.nDuration?.toString()) && (
                <div className={`${articleStyles.articleInfo} ${styles.articleInfo} d-flex`}>
                  {data?.oCategory?.sName && (
                    <span className="d-flex align-items-center text-primary text-uppercase font-weight-bold fw-bolder">
                      <CustomLink href={'/' + data?.oCategory?.oSeo?.sSlug || ''} prefetch={false}>
                        <a>{data?.oCategory?.sName}</a>
                      </CustomLink>
                    </span>
                  )}
                  {(data?.dPublishDisplayDate || data?.dPublishDate) && (
                    <span className="d-flex align-items-center">
                      <MyImage
                       src={Calendar}
                       alt="Calendar Icon"
                       height="18"
                       width="18" />
                       <span className='ms-1'>
                        {convertDt24h(dateCheck(data?.dPublishDisplayDate || data?.dPublishDate))}
                       </span>
                    </span>
                  )}
                  <span className="d-flex align-items-center">
                    <MyImage
                    src={View}
                    alt="View Icon"
                    height="18"
                    width="18"/>
                    {/* {data?.nDuration > 0 ? data?.nDuration : DEFAULT_BLOG_READ} <Trans i18nKey="common:Minute" /> */}
                    <span className='ms-1'>
                      {data?.nViewCount > -1 ? data?.nViewCount : DEFAULT_BLOG_READ}
                    </span>
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </article>
      )}
    </ArticleStyles>
  )
}
ArticleMedium.propTypes = {
  data: PropTypes.object,
  isVideo: PropTypes.bool,
  isFirstImg: PropTypes.bool,
  isSmall: PropTypes.bool,
  index: PropTypes.number
}
export default ArticleMedium
